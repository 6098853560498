import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Claims processing section consists of the following sections:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#claims-processing-flows"
        }}>{`Claims processing flows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#claim-structure"
        }}>{`Claim structure`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#processing-and-settlement-periods-and-payment-of-invoices"
        }}>{`Processing and Settlement periods and Payment of invoices`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#remittance-reports"
        }}>{`Reporting, statements and receipts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#remittance-reports"
        }}>{`Remittance reports`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#eligibility-checks-and-quotes"
        }}>{`Eligibility checks and quotes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#supported-providers-and-patients"
        }}>{`Supported Providers and Patients `}</a></li>
    </ul>
    <h3 {...{
      "id": "claims-processing-flows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#claims-processing-flows",
        "aria-label": "claims processing flows permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claims processing flows`}</h3>
    <p><img parentName="p" {...{
        "src": "/images/wsv-claim-flow-diagram.png",
        "alt": null
      }}></img></p>
    <p>{`WorkSafe Victoria claims - also called invoices - will have both synchronous and asynchronous outcomes. Approximately 60% of claims are adjudicated in real-time with a synchronous response provided within 30 seconds. The remainder of claims are asynchronous, with adjudication responses hours to typically two business days from submission. Partners will need to accommodate for asynchronous responses which can be pushed from Tyro Health via webhooks or periodically polled by partners via an invoice status API.`}</p>
    <p>{`Once a claim is submitted, the status can change as follows:`}</p>
    <ul>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Outstanding</Highlight>: claim is under review by WorkSafe Victoria 
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Approved</Highlight>: claim is approved for settlement
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Declined</Highlight>: claim was declined and will not be paid
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Cancelled</Highlight>: claim was canceled by the provider prior to settlement
      </li>
      <li parentName="ul">
        <Highlight mdxType="Highlight">Completed</Highlight>approved claim paid/settled to provider
      </li>
    </ul>
    <p>{`If multiple service items are claimed, each will have a unique item level status. Overall invoice status will change only where a final result has been obtained for each claim item. An invoice with at least one approved claim item will be marked as approved.`}</p>
    <p>{`Once a claim is approved, payment is issued the next business day. WorkSafe Victoria utilises a 5pm AET end of day. Claims approved after 5pm AET will be included in the following business day settlement period. If multiple claims are settled for the same provider, a single aggregate payment will be made. A remittance report is issued to providers on settlement which details each claim paid. If a provider has multiple claims - even from different insurers - or card payments issued by Tyro Health in the same settlement period, those claims will be included in the same aggregated settlement. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "claim-structure",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claim-structure",
        "aria-label": "claim structure permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claim structure`}</h4>
    <p>{`In WorkSafe Victoria, patients are referred to as “injured workers”. However, the details required to claim are similar to other compensatory funders. To issue a successful claim, the following details are required in each invoice:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Provider, profession and practice location`}</strong>{`: Usually set with a single provider number attribute. These details must be registered with Tyro Health in advance of a claim. Only one provider can claim per invoice. `}<br />{` `}</li>
      <li parentName="ul"><strong parentName="li">{`Patient details`}</strong>{`: including first and last name, date of birth and WSV issued claim number.`}</li>
      <li parentName="ul"><strong parentName="li">{`Service items`}</strong>{`: including service date, item number and fees are required. Optional attributes can include quantity, description and other item attributes. Multiple service items from multiple service dates can be claimed per invoice. `}</li>
    </ul>
    <p>{`Unlike other insurers, referral details are not required for medical specialists or allied healthcare providers. WorkSafe Victoria does not currently support invoice level notes or attachments. If an attachment is required to support a claim - such as a certificate of fitness/capacity - those details must be sent directly to WorkSafe Victoria
by the provider. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "processing-and-settlement-periods-and-payment-of-invoices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#processing-and-settlement-periods-and-payment-of-invoices",
        "aria-label": "processing and settlement periods and payment of invoices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Processing and settlement periods and payment of invoices`}</h4>
    <p>{`Providers can lodge WorkSafe Victoria claims 24/7.`}<br /></p>
    <p>{`Some claims will be processed instantly by WorkSafe Victoria using their straight-through adjudication method. Those claims will return an approved or declined status within 30 seconds and the SDK will return that status via a callback. `}<br /></p>
    <p>{`Invoices that are placed Under Review at the time of submission will be handled manually by a WorkSafe Victoria claims manager. This typically occurs during business hours Monday-Friday.`}<br /></p>
    <p>{`Once the provider's invoice has been approved by WorkSafe Victoria within Tyro Health, funds will be deposited to your nominated bank account the following business day by Tyro Health. `}<br /></p>
    <p>{`WorkSafe Victoria utilises a 5pm AET end of day. Claims approved after 5pm AET will be included in the following business day settlement period. If multiple claims are settled for the same provider, a single aggregate payment will be made. A remittance report is issued to providers on settlement which details each claim paid. If a provider has multiple claims - even from different insurers - or card payments issued by Tyro Health in the same settlement period, those claims will be included in the same aggregated settlement.`}<br /></p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "reporting-statements-and-receipts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#reporting-statements-and-receipts",
        "aria-label": "reporting statements and receipts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reporting, statements and receipts`}</h4>
    <p>{`Tyro Health automatically generates patient reports, statements and receipts on behalf of the partner/providers. These reports and receipts can be obtained from Medipass provider portal or via API calls. Patient reports, statements and receipts are also created and available online in my.medipass.io for patients with a Medipass member account.`}</p>
    <p>{`Invoices are automatically generated by Medipass for each injured worker and the treatments delivered. These invoices are directly sent to Worksafe victoria's for approval. Providers can print copies of the invoice from the Medipass provider portal.`}</p>
    <h4 {...{
      "id": "remittance-reports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#remittance-reports",
        "aria-label": "remittance reports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Remittance reports`}</h4>
    <p>{`Remittance reports are available on the connect portal Connect Portal when the payment has been made. Remittance reports will include all payments to a provider for the given settlement period which can include payments for other funding sources such as icare, Comcare and payment cards. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h4 {...{
      "id": "eligibility-checks-and-quotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#eligibility-checks-and-quotes",
        "aria-label": "eligibility checks and quotes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Eligibility checks and quotes`}</h4>
    <p>{`The WorkSafe Victoria integration provides a quote functionality that enables providers to check that a given claim number is valid and eligible to claim for the service provided, as well as the expected benefit.`}</p>
    <p>{`Within the PMS, quotes and claims can be implemented as stand-alone features or as a single interface with the ability for the user to toggle between quote and claim mode. `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "supported-providers-and-patients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#supported-providers-and-patients",
        "aria-label": "supported providers and patients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Supported providers and patients`}</h3>
    <h5 {...{
      "id": "professions-and-geographies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#professions-and-geographies",
        "aria-label": "professions and geographies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Professions and geographies`}</h5>
    <p>{`WorkSafe Victoria currently supports electronic claims from:`}</p>
    <ul>
      <li parentName="ul">{`General Practitioners`}</li>
      <li parentName="ul">{`Physiotherapists`}</li>
      <li parentName="ul">{`Pharmacies `}</li>
    </ul>
    <p>{`WorkSafe Victoria intends to extend this service to additional professions which will be enabled by Tyro Health on notice. `}</p>
    <p>{`WorkSafe Victoria is principally delivered by Victorian healthcare providers. However, providers from other states - typically near border towns - can register and deliver services to eligible patients. `}</p>
    <p>{`Providers must first register with Tyro Health prior to submitting a WorkSafe Victoria claim. This registration process is self-service and can be completed within a few minutes.  If providers deliver services from multiple locations or businesses, each unique provider number must be registered. Details on provider registration and WorkSafe Victoria activation is provided at: `}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/5834449-enable-worksafe-victoria-digital-invoicing"
      }}>{`https://help.medipass.com.au/en/articles/5834449-enable-worksafe-victoria-digital-invoicing`}</a>{` `}</p>
    <Divider marginTop="major-4" marginBottom="major-4" mdxType="Divider" />
    <h3 {...{
      "id": "patients",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patients",
        "aria-label": "patients permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patients`}</h3>
    <p>{`Injured workers with a valid and active claim number can use the service. `}</p>
    <p>{`WorkSafe Victoria has multiple external agents who help to manage claims or process employer premiums. The service has been designed to support all external agents, which include:`}</p>
    <ul>
      <li parentName="ul">{`Allianz`}</li>
      <li parentName="ul">{`EML`}</li>
      <li parentName="ul">{`CGU (note agent is no longer active)`}</li>
      <li parentName="ul">{`Gallagher Bassett`}</li>
      <li parentName="ul">{`TAC`}</li>
      <li parentName="ul">{`Xchanging`}</li>
    </ul>
    <p>{`Details of agents can be returned in successful claims. `}</p>
    <p>{`Though rare, patients may have more than one active case. In this event, the patient will have a unique claim number for each active case. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      